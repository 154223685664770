import request from '@/router/axios'

// 分页查询陵园列表
export function getCemeteryListApi(query) {
  return request({
    url: '/admin/cemetery/page',
    method: 'get',
    params: query
  })
}
// 添加-修改陵园
export function postEditApi(obj) {
  return request({
    url: '/admin/cemetery/addOrUpdate',
    method: 'post',
    data: obj
  })
}
// 修改排序
export function postUpdateRankApi(obj) {
  return request({
    url: `/admin/cemetery/updateRank/${obj.id}/${obj.operateType}`,
    method: 'post'
  })
}
// 上下线
export function posUpdatePublishApi(obj) {
  return request({
    url: `/admin/cemetery/publishOrUnpublish/${obj.id}/${obj.published}`,
    method: 'post'
  })
}


<template>
  <Basic-Container>
    <avue-crud class="avueCrud" ref="crud" v-model="crudModel" :page.sync="page" :data="tableData" @row-save="rowSave"
      @row-update="rowUpdate" @search-change="searchChange" @size-change="sizeChange" @current-change="currentChange"
      @refresh-change="refreshChange" @search-reset="searchReset" :before-open="beforeOpen"
      :table-loading="tableLoading" :option="tableOption">
      <template slot-scope="{ row }" slot="cityCodeSearch">
        <el-select filterable clearable v-model="row.cityCode" placeholder="请选择">
          <el-option v-for="item in citys" :key="item.code" :label="item.name" :value="item.code">
          </el-option>
        </el-select>
      </template>
      <template v-slot:sequence="{ row }">
        <div class="sort-css">
          <div>{{ row.sequence }}</div>
          <div class="sort-icon" v-if="row.sequence !== 1" @click="sortClick('top', row)">
            <el-button class="icon-btn" type="primary" icon="el-icon-top"></el-button>
          </div>
          <div class="sort-icon" @click="sortClick('bottom', row)">
            <el-button class="icon-btn" type="warning" icon="el-icon-bottom"></el-button>
          </div>
        </div>
      </template>
      <template v-slot:location="{ row }">
        {{ row.provinceName + "-" + row.cityName + "-" + row.regionName }}
      </template>
      <template v-slot:published="{ row }">
        {{ row.published == 0 ? "下线" : "上线" }}
      </template>
      <template v-slot:tag="{ row }">
        <div class="tabel-tag">
          <el-tag type="success" v-for="item of row.tag" :key="item">{{
      item
    }}</el-tag>
        </div>
      </template>
      <template slot="locationForm">
        <el-cascader clearable @change="changeLocation" v-model="crudModel.location" :props="props"
          :options="pca"></el-cascader>
      </template>
      <template slot="tagForm">
        <div class="tag-css">
          <el-tag class="tag-item" :key="tag" v-for="tag in crudModel.tag" closable :disable-transitions="false"
            @close="handleClose(tag)">
            {{ tag }}
          </el-tag>

          <el-input class="input-new-tag" v-if="inputVisible" v-model="tagValue" ref="saveTagInput" size="small"
            @keyup.enter.native="handleInputConfirm" @blur="handleInputConfirm">
          </el-input>
          <template v-else>
            <el-button v-show="crudModel.tag && crudModel.tag.length < 6" class="button-new-tag" size="small"
              @click="showInput">+添加标签</el-button>
          </template>
        </div>
      </template>
      <template slot="listImagesForm">
        <UploadBtn tips="最多上传20张" :limit="20" v-model="crudModel.listImages" />
      </template>
      <template slot="headImagesForm">
        <UploadBtn tips="最多上传20张" :limit="20" v-model="crudModel.headImages" />
      </template>
      <template slot="videoUrlForm">
        <UploadBtn tips="非必填，只可上传一个视频" type="video" v-model="crudModel.videoUrl" />
      </template>
      <template slot="environmentImagesForm">
        <UploadBtn tips="最多上传20张" :limit="20" v-model="crudModel.environmentImages" />
      </template>
      <template slot="introduceImagesForm">
        <UploadBtn tips="最多上传20张" :limit="20" v-model="crudModel.introduceImages" @change-up="changeUp" />
      </template>
      <template slot="steleNamesForm">
        <el-input v-for="(item, index) of steleNames" :key="index + 'steleNamesForm'" v-model="steleNames[index].text"
          placeholder="请输入内容"></el-input>
      </template>
      <template slot="cemeteryPositionForm">
        <el-input class="PositionCss" placeholder="请输入园区位置经纬度坐标" v-model="crudModel.cemeteryPosition">
          <template slot="append">
            <el-link type="primary" href="https://lbs.amap.com/tools/picker" target="_blank">去高德查询经纬度坐标</el-link>
          </template>
        </el-input>
      </template>
      <template slot-scope="{ type, size, row }" slot="menu">
        <el-button :size="size" :type="type" @click="setPublished(row)">{{
      row.publishedStr
          }}</el-button>
      </template>
    </avue-crud>
  </Basic-Container>
</template>
<script>
import {
  getCemeteryListApi,
  postEditApi,
  postUpdateRankApi,
  posUpdatePublishApi,
} from "@/api/xmwmanage/cemetery.js";
import { tableOption } from "./tableOption.js";
import { citys, getName, pca } from "@/util/location/index.js";
let vm;
export default {
  name: "recommended",
  components: {
    UploadBtn: () => import("@/components/uploadList/index.vue"),
  },
  filters: {},
  watch: {
    steleNames: {
      deep: true,
      handler: function (val) {
        console.log(val);
        this.crudModel.steleNames = val
      }
    }
  },
  data() {
    return {
      searchForm: {},
      crudModel: {
        tag: [],
      },
      tableLoading: false,
      tableData: [],
      tableOption: tableOption,
      page: {
        total: 10, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 20, // 每页显示多少条
        pageSizes: [10, 20, 30, 40, 50, 100],
      },
      pca: pca,
      props: {
        value: "code",
        label: "name",
        // lazy: true,
        // lazyLoad(node, resolve) {
        //   const { level, label, value } = node;
        //   let data = getNextData(node.value, level);
        //   let nodes = data.map((item) => {
        //     return {
        //       value: item.code,
        //       label: item.name,
        //       leaf: level >= 2,
        //     };
        //   });
        //   resolve(nodes);
        // },
      },
      tagValue: "",
      inputVisible: false,
      showLocationForm: false,
      citys: citys,
      steleNames: [
        {
          text: "",
        },
      ],
    };
  },
  created() {
    vm = this;
    this.getList(this.page);
  },
  computed: {},
  methods: {
    getList(page, params) {
      this.tableLoading = true;
      getCemeteryListApi(
        Object.assign(
          {
            current: page.currentPage,
            size: page.pageSize,
          },
          params,
          this.searchForm
        )
      )
        .then((response) => {
          let data = response.data.data;
          if (data.records && data.records.length > 0) {
            for (let i = 0; i < data.records.length; i++) {
              const item = data.records[i];
              let price = Number(item.price || 0);
              price = price / 100;
              if (
                (price + "").split(".")[1] &&
                (price + "").split(".")[1].length > 2
              ) {
                price = price.toFixed(2);
              }
              item.price = price;
              item.publishedStr = item.published == "0" ? "上线" : "下线";
            }
          }
          this.tableData = data.records || [];
          this.page.total = Number(data.total) || 0;
          this.tableLoading = false;
        })
        .catch(() => {
          this.$message.error("查询失败");
          this.tableLoading = false;
        });
    },
    searchChange(form, done) {
      this.searchForm = form;
      this.page.currentPage = 1;
      this.getList(this.page, form);
      done();
    },
    sizeChange(pageSize) {
      this.page.currentPage = 1;
      this.page.pageSize = pageSize;
      this.getList(this.page);
    },
    currentChange(current) {
      this.page.currentPage = current;
      this.getList(this.page);
    },
    async rowSave(form, done, loading) {
      loading();
      let params = JSON.parse(JSON.stringify(form));
      let newParams = await this.setParams(params, "add");
      postEditApi(newParams)
        .then((res) => {
          this.getList(this.page);
          this.$message.success("新增成功");
          done();
        })
        .catch((err) => {
          this.$message.error("新增失败");
        });
    },
    async rowUpdate(form, index, done, loading) {
      let params = JSON.parse(JSON.stringify(form));
      let newParams = await this.setParams(params, "edit");
      postEditApi(newParams)
        .then((res) => {
          this.$message.success("修改成功");
          this.getList(this.page);
          done();
        })
        .catch((err) => {
          loading();
          this.$message.warning("网络错误,请稍候重试");
        });
    },
    beforeOpen(done, type) {
      if (type === "add") {
        this.$nextTick(() => {
          setTimeout(() => {
            this.crudModel.listImages = [];
            this.crudModel.headImages = [];
            this.crudModel.videoUrl = [];
            this.crudModel.tag = [];
            this.crudModel.environmentImages = [];
            this.crudModel.introduceImages = [];
            this.steleNames = [
              {
                text: "",
              },
            ]
          }, 0);
        });
      } else {
        let row = this.crudModel;
        this.crudModel.videoUrl = [row.videoUrl];
        let arr = []
        if (this.crudModel.steleNames) {
          for (let i = 0; i < this.crudModel.steleNames.length; i++) {
            const item = this.crudModel.steleNames[i];
            arr.push({
              text: item
            })
          }
        }
        this.steleNames = arr.length ? arr : this.steleNames
        this.$set(this.crudModel, "location", [
          row.provinceCode,
          row.cityCode,
          row.regionCode,
        ]);
        // this.crudModel.location =
      }
      this.$forceUpdate();
      done();
    },
    refreshChange() {
      this.getList(this.page);
    },
    changeLocation(data) {
      console.log(data, "hjk");
    },
    handleClose(tag) {
      this.crudModel.tag.splice(this.crudModel.tag.indexOf(tag), 1);
    },
    handleInputConfirm() {
      let tagValue = this.tagValue;
      if (tagValue) {
        if (this.crudModel.tag.includes(tagValue)) {
          return this.$message.error("请勿重复添加标签");
        }
        this.crudModel.tag.push(tagValue);
        console.log(this.crudModel.tag, " this.crudModel.tag");
      }
      this.inputVisible = false;
      this.tagValue = "";
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    setParams(params, type = "edit") {
      return new Promise((resolve) => {
        let newParams = {
          addressDetail: params.addressDetail,
          cemeteryIntroduce: params.cemeteryIntroduce,
          cemeteryName: params.cemeteryName,
          cemeteryPosition: params.cemeteryPosition,
          cityCode: params.location[1],
          cityName: getName(params.location[1], 1),
          description: params.description,
          environmentImages: params.environmentImages,
          headImages: params.headImages,
          introduceImages: params.introduceImages, //临时
          listImages: params.listImages,
          price: (params.price * 100).toFixed(0),
          priceChinese: params.price,
          provinceCode: params.location[0],
          provinceName: getName(params.location[0], 0),
          published: params.published || 0,
          regionCode: params.location[2],
          regionName: getName(params.location[2], 2),
          sequence: params.sequence || 0,
          tag: params.tag,
          code: params.code,
          steleNames: this.setFormS(),
          seoTitle: params.seoTitle,
          seoDesc: params.seoDesc,
          seoKeywords: params.seoKeywords,
        };
        if (params.videoUrl[0]) {
          newParams.videoUrl = params.videoUrl[0];
        }
        if (type === "edit") {
          newParams.id = params.id;
          newParams.published = params.published;
        }
        resolve(newParams);
      });
    },
    sortClick(type, row) {
      let str = type === "top" ? "提升" : "降低";
      let id = row.id;
      this.$confirm(`确定${str} ${row.cemeteryName} 的排序吗 ?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let operateType = type === "top" ? 1 : 2;
          postUpdateRankApi({
            id,
            operateType,
          }).then((res) => {
            this.$message.success("操作成功!");
            this.getList(this.page);
          });
        })
        .catch(() => { })
        .finally(() => { });
    },
    setPublished(row) {
      let str = row.publishedStr;
      let id = row.id;
      let published = row.published == "0" ? 1 : 0;
      this.$confirm(`确定${str} ${row.cemeteryName} 吗 ?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          posUpdatePublishApi({
            id,
            published,
          }).then((res) => {
            this.$message.success("操作成功!");
            this.getList(this.page);
          });
        })
        .catch(() => { })
        .finally(() => { });
    },
    searchReset(from) {
      if (from.cityCode) {
        from.cityCode = "";
      }
      this.searchForm.cityCode && (this.searchForm.cityCode = "");
    },
    changeUp(data) {
      if (data.type == "push") {
        this.steleNames.splice(data.index, 1, {
          text: "",
        });
        console.log(this.steleNames, 'this.steleNames');
      } else {
        this.steleNames.splice(data.index, 1);
      }
    },
    setFormS() {
      let arr = []
      for (let i = 0; i < this.steleNames.length; i++) {
        const item = this.steleNames[i];
        arr.push(item.text)
      }
      return arr
    }
  },
};
</script>
<style lang="scss" scoped>
.PositionCss {
  width: 400px;
}

.tag-css {
  display: flex;

  .tag-item {
    margin-right: 10px;
  }
}

.tabel-tag {
  display: flex;
  flex-direction: column;

  .el-tag {
    margin-bottom: 10px;
  }
}

.sort-css /deep/ {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;

  .sort-icon {
    margin: 0 4px;
  }

  .icon-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    padding: 2px 4px;
    font-size: 18px;
  }
}
</style>
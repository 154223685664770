export const tableOption = {
  border: true,
  index: false,
  stripe: true,
  menuAlign: 'center',
  align: 'center',
  searchMenuSpan: 6,
  highlightCurrentRow: true,
  menuWidth: 300,
  viewBtn: false,
  delBtn: false,
  tip: false,
  columnBtn: false,
  menu: true,
  searchLabelWidth: 100,
  labelWidth: 100,
  addBtn: true,
  addBtnText: '新建陵园',
  refreshBtn: true,
  editBtnText: '修改',
  labelWidth: 200,
  column: [
    {
      type: 'input',
      label: 'ID',
      prop: 'id',
      addDisplay: false,
      editDisplay: false,
      hide: true
    },
    {
      type: 'input',
      label: '排序',
      prop: 'sequence',
      addDisplay: false,
      editDisplay: false,
      slot: true
    },
    {
      type: 'input',
      label: '陵园名称',
      prop: 'cemeteryName',
      span: 20,
      rules: [{
        required: true,
        message: "请输入陵园名称",
        trigger: "blur"
      }]
    },
    {
      type: 'input',
      label: 'SEO标题',
      prop: 'seoTitle',
      span: 20,
      rules: [{
        required: true,
        message: "请输入SEO标题",
        trigger: "blur"
      }]
    },
    {
      type: 'input',
      label: 'SEO描述',
      prop: 'seoDesc',
      span: 20,
      rules: [{
        required: true,
        message: "请输入SEO描述",
        trigger: "blur"
      }]
    },
    {
      type: 'input',
      label: 'SEO关键词',
      prop: 'seoKeywords',
      span: 20,
      rules: [{
        required: true,
        message: "请输入SEO关键词",
        trigger: "blur"
      }]
    },
    {
      type: 'input',
      label: '陵园编码',
      prop: 'code',
      span: 20,
      addDisplay: false,
      editDisplay: false
    },
    {
      type: 'select',
      label: '城市',
      prop: 'cityCode',
      search: true,
      overHidden: true,
      addDisplay: false,
      editDisplay: false,
      hide: true
    },
    {
      type: 'input',
      label: '所在地（省/市/区）',
      prop: 'location',
      slot: true,
      width: 180,
      span: 20,
      formslot: true,
      rules: [{
        required: true,
        message: "请选择所在地（省/市/区)",
        trigger: "change"
      }]
    },
    {
      type: 'input',
      label: '详细地址',
      prop: 'addressDetail',
      span: 20,
      rules: [{
        required: true,
        message: "请输入详细地址",
        trigger: "blur"
      }],
      overHidden: true,
    },
    {
      type: 'input',
      label: '起售价',
      prop: 'price',
      span: 20,
      append:'元',
      type:'number',
      controls:false,
      rules: [{
        required: true,
        message: "请输入起售价",
        trigger: "blur"
      }]
    },
    {
      type: 'textarea',
      label: '亮点',
      prop: 'description',
      span: 20,
      maxlength: 50,
      showWordLimit:true,
      rules: [{
        required: true,
        message: "请输入亮点",
        trigger: "blur"
      }],
      overHidden: true,
    },
    {
      type: 'textarea',
      label: '园区介绍',
      prop: 'cemeteryIntroduce',
      span: 20,
      hide: true,
      maxlength: 800,
      showWordLimit:true,
      rules: [{
        required: true,
        message: "请输入园区介绍",
        trigger: "blur"
      }]
    },
    {
      type: 'input',
      label: '标签',
      prop: 'tag',
      span: 20,
      formslot: true,
      slot: true,
      rules: [{
        required: true,
        message: "请添加标签",
        trigger: "change"
      }]
    },
    {
      type: 'input',
      label: '上线状态',
      prop: 'published',
      addDisplay: false,
      editDisplay: false,
      span: 20,
      slot: true
    },
    {
      type: 'input',
      label: '创建时间',
      prop: 'createTime',
      addDisplay: false,
      editDisplay: false,
      width: 150,
    },
    {
      type: 'input',
      label: '列表图',
      prop: 'listImages',
      slot: true,
      span: 20,
      formslot: true,
      hide: true,
      rules: [{
        required: true,
        message: "请上传列表图",
        trigger: "change"
      }]
    },
    {
      type: 'input',
      label: '头图',
      prop: 'headImages',
      slot: true,
      span: 20,
      formslot: true,
      hide: true,
      rules: [{
        required: true,
        message: "请上传头图",
        trigger: "blur"
      }]
    },
    {
      type: 'input',
      label: '视频',
      prop: 'videoUrl',
      slot: true,
      span: 20,
      formslot: true,
      hide: true
    },
    {
      type: 'input',
      label: '园区环境',
      prop: 'environmentImages',
      slot: true,
      span: 20,
      formslot: true,
      hide: true,
      rules: [{
        required: true,
        message: "请上传园区环境",
        trigger: "blur"
      }]
    },
    {
      type: 'input',
      label: '碑型介绍',
      prop: 'introduceImages',
      slot: true,
      span: 20,
      formslot: true,
      hide: true,
      rules: [{
        required: true,
        message: "请上传碑型介绍",
        trigger: "blur"
      }]
    },
    {
      type: 'input',
      label: '碑型名称',
      prop: 'steleNames',
      slot: true,
      span: 20,
      formslot: true,
      hide: true,
      rules: [{
        required: true,
        message: "请输入碑型名称",
        trigger: "blur"
      }]
    },
    {
      type: 'input',
      label: '园区位置',
      prop: 'cemeteryPosition',
      slot: true,
      span: 20,
      formslot: true,
      hide: true,
      rules: [{
        required: true,
        message: "请输入园区位置",
        trigger: "blur"
      }]
    }
  ]
}
